<template>
  <div>
    <div class="contract-info">
      <customer-form
        :contract="contract"
      />
      <debtor-form
        :contract="contract"
      />
    </div>

    <debt-content
      :contract="contract"
    />

    <debt-info-by-customer
      :contract="contract"
    />

    <attachments
      ref="attachments"
      :document-files.sync="contract.document_files"
      @all-updated="updateDocumentFiles"
    />

    <!--    fixed btn-->
    <fixed-right-button
      :buttons="actionsButton"
      @click="handleActionsButton"
    />
  </div>
</template>

<script>
import { VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import contractStoreModule from '@/views/contracts/contractStoreModule'
import FixedRightButton from '@core/components/fixed-button/Fixed-Right-Button.vue'
import Attachments from '@/views/contracts/create/Attachments.vue'
import ability from '@/libs/acl/ability'
import CustomerForm from './CustomerForm.vue'
import DebtorForm from './DebtorForm.vue'
import DebtContent from './DebtContent.vue'
import DebtInfoByCustomer from './DebtInfoByCustomer.vue'
import useContractsCreate from './useContractsCreate'

export default {
  components: {
    CustomerForm,
    DebtorForm,
    DebtContent,
    DebtInfoByCustomer,
    Attachments,
    FixedRightButton,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      isUploading: false,
    }
  },
  computed: {
    actionsButton() {
      const defaultActions = []
      if (ability()
        .can('create', 'Contract')) {
        defaultActions.push({
          label: 'Tạo hồ sơ',
          icon: 'FileTextIcon',
          value: 'create',
        })
      }
      return defaultActions
    },
  },
  created() {
    if (localStorage.fake) this.fakeContractData()
  },
  methods: {
    handleActionsButton(btn) {
      if (btn.value === 'create') {
        const pending = this.$refs.attachments.$refs.uploads.$refs.uploads.getQueuedFiles()
        if (pending && pending.length) {
          this.isUploading = true
          this.$refs.attachments.$refs.uploads.$refs.uploads.processQueue()
        } else {
          this.addContract()
        }
      }
    },
    updateDocumentFiles(files) {
      if (!this.isUploading) return
      this.contract.document_files = files
      this.addContract()
    },
    checkContractBeforeCreate(c) {
      const errs = []
      if (!c.debtor || !c.debtor.category) {
        errs.push('Nhóm Khách Nợ không được để trống!')
      }
      return errs
    },
    addContract() {
      const errs = this.checkContractBeforeCreate(this.contract) || []
      if (errs.length) {
        this.$toastr(errs.join(', '), 'danger')
        return
      }
      this.$action(this.createContract(this.contract), true)
        .then(({ id }) => {
          this.$router.push({
            name: 'contracts-detail',
            params: { id },
          })
        })
    },
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = 'contract'
    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_STORE_MODULE_NAME)
    })

    return {
      ...useContractsCreate(),
    }
  },
}
</script>
<style lang="scss">
.contract-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: .5rem;
}

@media (max-width: 992px) {
  .contract-info {
    grid-template-columns: 1fr;
  }
}
</style>
